export const decodeToken = token => {
    //decode base64
    let decodedToken = decodeURIComponent(escape(atob(token)));
    return JSON.parse(decodedToken);
}

// export const decodeToken = token => {
//     // var base64Url = token.split('.')[1];
//     // var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
//     // var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
//     //     return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
//     // }).join(''));
//     //return JSON.parse(jsonPayload);
// }

export const validateEmail = mail => {
    if (mail === null || mail === '')
        return false;
    let mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return mail.match(mailFormat) ? true : false;

}

export const getddMMyyyyDate = date => {
    return (("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear());
}

export const getDateWithDayDifference = (date, difference) => {
    const tmpDate = new Date();
    return new Date(tmpDate.setDate(date.getDate() - difference));
}

//string veriyi datee çevirerek servise iletiyoruz
export const stringToDate = (strDate, hour = 0, minute = 0, second = 0) => {
    let dateArr = strDate !== null && strDate.split("/");
    return strDate !== null && new Date(dateArr[2], parseInt(dateArr[1]) - 1, parseInt(dateArr[0]), hour, minute, second);
}

//Boş gelen verileri ekrana istediğimiz metin ile yazdırmamızı sağlıyor
export const isItSpecified = (value, message) => {
    return value ? value : message;
}

//ISO Formatlı datetime içerikleri formatlamak için kullanılıyor
export const ISOStringToDate = (date, timeZone) => {
    let tmpDate = new Date(date);

    if (date.includes("T")) {
        const dateArr = date.split("T");
        const dateTmpTime = dateArr[1].split(":");
        tmpDate = new Date(Date.UTC(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate(), dateTmpTime[0], dateTmpTime[1], dateTmpTime[2].split(".")[0]));
    }

    let returnDate = ("0" + tmpDate.getDate()).slice(-2) + "." + ("0" + (tmpDate.getMonth() + 1)).slice(-2) + '.' + tmpDate.getFullYear();
    let tmpTime = tmpDate.getUTCHours() + ":" + tmpDate.getUTCMinutes();
    return returnDate + " / " + addTimeZone(tmpTime, timeZone)
}

//ISO Formatlı datetime içerikleri formatlamak için kullanılıyor. TimeZone ihtiyacı duyulmuyor
export const ISOStringToDate2 = date => {
    let tmpDate = new Date(date);
    let returnDate = ("0" + tmpDate.getDate()).slice(-2) + "." + ("0" + (tmpDate.getMonth() + 1)).slice(-2) + '.' + tmpDate.getFullYear()
    return returnDate
}

//ISO Formatlı datetime içerikleri formatlamak için kullanılıyor. Tarihin yanına saatte ekleniyor. TimeZone ihtiyacı duyulmuyor
export const ISOStringToDateWithTime = date => {
    let tmpDate = new Date(date);
    let returnDate = ("0" + tmpDate.getDate()).slice(-2) + "." + ("0" + (tmpDate.getMonth() + 1)).slice(-2) + '.' + tmpDate.getFullYear()
    let returnTime = ("0" + tmpDate.getHours()).slice(-2) + ":" + ("0" + tmpDate.getMinutes()).slice(-2) + ":" + ("0" + tmpDate.getSeconds()).slice(-2);

    return returnDate + " / " + returnTime;
}

//Full Date formatını Short Date(2023-03-16T00:00:00) formatına çeviren fonksiyon.
export const fullDateToShortDate = dateString => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    return formattedDate;
}

//Full Date formatını Short Date(2023-03-16T00:00:00) formatına çeviren fonksiyon.
export const fullDateToShortDateWithTime = (dateString, hour = 0, minute = 0, second = 0) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = hour.toString().padStart(2, "0");
    const minutes = minute.toString().padStart(2, "0");
    const seconds = second.toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    return formattedDate;
}

//Full date formatını yıl-ay-gün değerleri bitiş olacak şekilde(20230302) formatlayan fonksiyon.
export const fullDateToShortDate2 = dateString => {
    const year = dateString.getFullYear();
    const month = ('0' + (dateString.getMonth() + 1)).slice(-2);
    const day = ('0' + dateString.getDate()).slice(-2);
    return `${year}${month}${day}`;
}

//Date string'leri okunabilir hale getirir. (Saatli bir şekilde)
export const dateStringToReadableDate = (dateString) => {
    const [date, time] = dateString.split(' ');
    const dateParts = date.split('.');
    const day = dateParts[0].length === 1 ? '0' + dateParts[0] : dateParts[0];
    const month = dateParts[1].length === 1 ? '0' + dateParts[1] : dateParts[1];
    const year = dateParts[2];
    const timeParts = time.split(':');
    const hour = timeParts[0].length === 1 ? '0' + timeParts[0] : timeParts[0];
    const minute = timeParts[1].length === 1 ? '0' + timeParts[1] : timeParts[1];
    return day + '.' + month + '.' + year + ' / ' + hour + ':' + minute;
}

//Date string'leri okunabilir hale getirir. (Saatsiz bir şekilde)
export const dateStringToReadableDateWithoutTime = (dateString) => {
    let dateArr = dateString.split(".");
    return ("0" + dateArr[0]).slice(-2) + "." + ("0" + dateArr[1]).slice(-2) + "." + dateArr[2];
}

//Eğitmen detayı verileri bölünerek saate employee(kullanıcıya) ait timezone ekleniyor
export const addTimeZoneToFullDate = (date, timeZone) => {
    let tempArr = date.split(" ");
    //Saate çalışana ait timezone ekleniyor
    let tempTime = tempArr[1].replace(/^\s+|\s+$/gm, '');
    let lessonTime = addTimeZone(tempTime, timeZone);
    //Tarih formatları bazen 4.01.2023 gibi geliyor. Bunu 04.01.2023 şeklinde standarta getiriyoruz
    let tempDateArr = tempArr[0].split(".");
    let tempDate = ("0" + tempDateArr[0]).slice(-2) + "." + ("0" + tempDateArr[1]).slice(-2) + "." + tempDateArr[2];

    return tempDate + " / " + lessonTime;
}

// "30.09.2023 23:59" formatını "2023-09-30T23:59:00" formatına çeviren fonskiyon
export const convertReadableDateToShortDateFormat = (dateString) => {
    let splitedTimeArr = dateString.split(" ");
    let date = splitedTimeArr[0];
    let time = splitedTimeArr[1];

    return date + " " + time;
}

//04.03.2022 22:00 gibi tarihlere timezone eklemek için kullanılıyor
export const addTimeZoneToFullDate2 = (date, timeZone) => {
    let tempArr = date.split(" ");
    let tmpDate = new Date(tempArr[0].replace(/\./g, "-").split("-").reverse().join("-"));
    const dateTmpTime = tempArr[1].split(":");
    tmpDate = new Date(Date.UTC(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate(), parseInt(dateTmpTime[0]), parseInt(dateTmpTime[1]), 0));

    let returnDate = ("0" + tmpDate.getDate()).slice(-2) + "." + ("0" + (tmpDate.getMonth() + 1)).slice(-2) + '.' + tmpDate.getFullYear();
    let tmpTime = tmpDate.getUTCHours() + ":" + tmpDate.getUTCMinutes();
    return returnDate + " / " + addTimeZone(tmpTime, timeZone)
}

//Timezone eklemek için kullanılan fonksiyon
export const addTimeZone = (tm, timeZone) => {
    let today = new Date();
    let timeArr = tm.split(":");
    let time = new Date(today.getFullYear(), today.getMonth(), today.getDay(), parseInt(timeArr[0]), parseInt(timeArr[1]));
    let returnTime = new Date(time.setMinutes(time.getMinutes() + parseInt(timeZone)));
    return ("0" + returnTime.getHours()).slice(-2) + ':' + ("0" + returnTime.getMinutes()).slice(-2);
}

export const adjustTimeWithTimeZone = (time, employeeTimeZone) => {
    const adjustedHour = (parseInt(time.split(':')[0]) - Math.floor(employeeTimeZone / 60) + 24) % 24;
    const adjustedTime = `${adjustedHour.toString().padStart(2, "0")}:${time.split(':')[1]}`;
    return adjustedTime;
}

export const getLanguageTypeByLanguageKey = (languageKey) => {
    switch(languageKey) {
        case 'tr':
            return 1;
        case 'az':
            return 2;
        case 'ar':
            return 3;
        case 'en':
            return 4;
    }
}

export const convertStringToPercentageFormat = (percentageString) => {
    let number = parseFloat(percentageString.replace(",", "."));

    let formattedPercentage = "%0"

    if (!isNaN(number) && number > 0 && number <= 1) {
        let percentage = (number * 100).toFixed(2);
        formattedPercentage = "%" + percentage.replace(".00", "");
    }

    return formattedPercentage;
}

export const iconForLessonStatus = (status, language) => {
    switch (status) {
        case 1:
            return { iconName: "schedule", color: "", tooltip: language === "tr" ? 'Aktif' : language === 'en' ? 'Active' : language === 'ar' ? 'Aktif' : language === 'az' ? 'Aktif' : '' };
        case 2:
            return { iconName: "done", color: "text-green", tooltip: language === "tr" ? 'Katıldı' : language === 'en' ? 'Attented' : language === 'ar' ? 'Katıldı' : language === 'az' ? 'Katıldı' : '' };
        case 3:
            return { iconName: "close", color: "text-red", tooltip: language === "tr" ? 'Dersi Kaçırdı' : language === 'en' ? 'Missed' : language === 'ar' ? 'Dersi Kaçırdı' : language === 'az' ? 'Dersi Kaçırdı' : '' };
        case 4:
            return { iconName: "history", color: "text-blue", tooltip: language === "tr" ? 'Erteledi' : language === 'en' ? 'Postponed' : language === 'ar' ? 'Erteledi' : language === 'az' ? 'Erteledi' : '' };
        case 5:
            return { iconName: "ac_unit", color: "", tooltip: language === "tr" ? 'Dondurdu' : language === 'en' ? 'Donduruldu' : language === 'ar' ? 'Donduruldu' : language === 'az' ? 'Donduruldu' : '' };
        case 6:
            return { iconName: "hotel", color: "", tooltip: language === "tr" ? 'Tatil' : language === 'en' ? 'Holiday' : language === 'ar' ? 'Tatil' : language === 'az' ? 'Tatil' : '' };
        case 7:
            return { iconName: "category", color: "", tooltip: language === "tr" ? 'Hafta Sonu' : language === 'en' ? 'Weekend' : language === 'ar' ? 'Hafta Sonu' : language === 'az' ? 'Hafta Sonu' : '' };
        case 8:
            return { iconName: "do_not_disturb_on", color: "", tooltip: language === "tr" ? 'Ders Yok' : language === 'en' ? 'No Lesson' : language === 'ar' ? 'Ders Yok' : language === 'az' ? 'Ders Yok' : '' };
        case 9:
            return { iconName: "construction", color: "", tooltip: language === "tr" ? 'Sistem Erteleme' : language === 'en' ? 'System Postpone' : language === 'ar' ? 'Sistem Erteleme' : language === 'az' ? 'Sistem Erteleme' : '' };
        case 10:
            return { iconName: "delete", color: "", tooltip: language === "tr" ? 'Silindi' : language === 'en' ? 'Deleted' : language === 'ar' ? 'Silindi' : language === 'az' ? 'Silindi' : '' };
        default:
            return { iconName: "", color: "", tooltip: "" };
    }
}


//Gönderilen güne göre, günün key'ini döndüren fonksiyon
export const convertDayToKey = (day) => {

    let lowerDay = day.toLocaleLowerCase();

    if (lowerDay === 'monday' || lowerDay === 'pazartesi')
        return 1;
    else if (lowerDay === 'tuesday' || lowerDay === 'salı')
        return 2;
    else if (lowerDay === 'wednesday' || lowerDay === 'çarşamba')
        return 3;
    else if (lowerDay === 'thursday' || lowerDay === 'perşembe')
        return 4;
    else if (lowerDay === 'friday' || lowerDay === 'cuma')
        return 5;
    else if (lowerDay === 'saturday' || lowerDay === 'cumartesi')
        return 6;
    else if (lowerDay === 'sunday' || lowerDay === 'pazar')
        return 7;
    else
        return -1;
}

//Hafta günlerinin çevirisinde kullanılan fonksiyon
export const translateWeekDays = (day, language) => {

    let key = convertDayToKey(day);
    let result = '';

    switch (key) {
        case 1:
            result = language === 'tr' ? 'Pazartesi' : language === 'en' ? 'Monday' : language === 'ar' ? 'Pazartesi' : language === 'az' ? 'Pazartesi' : '';
            return result;
        case 2:
            result = language === 'tr' ? 'Salı' : language === 'en' ? 'Tuesday' : language === 'ar' ? 'Salı' : language === 'az' ? 'Salı' : '';
            return result;
        case 3:
            result = language === 'tr' ? 'Çarşamba' : language === 'en' ? 'Wednesday' : language === 'ar' ? 'Çarşamba' : language === 'az' ? 'Çarşamba' : '';
            return result;
        case 4:
            result = language === 'tr' ? 'Perşembe' : language === 'en' ? 'Thursday' : language === 'ar' ? 'Perşembe' : language === 'az' ? 'Perşembe' : '';
            return result;
        case 5:
            result = language === 'tr' ? 'Cuma' : language === 'en' ? 'Friday' : language === 'ar' ? 'Cuma' : language === 'az' ? 'Cuma' : '';
            return result;
        case 6:
            result = language === 'tr' ? 'Cumartesi' : language === 'en' ? 'Saturday' : language === 'ar' ? 'Cumartesi' : language === 'az' ? 'Cumartesi' : '';
            return result;
        case 7:
            result = language === 'tr' ? 'Pazar' : language === 'en' ? 'Sunday' : language === 'ar' ? 'Pazar' : language === 'az' ? 'Pazar' : '';
            return result;
        default:
            return '';
    }

}

// Kopyala/yapıştır ile latin harfleri/karakterleri dışında input girilmesi engelleniyor.
export const allowPasteLatinCharacters = (e) => {
    const pastedText = e.clipboardData.getData('text');

    const latinAlphabetRegex = /^[a-zA-Z\s]*$/;

    if (!latinAlphabetRegex.test(pastedText)) {
        e.preventDefault();
        return;
    }
};

//onKeyDown sırasında latin harfleri dışında karakter girilmesi engelleniyor.
export const allowLatinCharacters = (e) => {
    const turkishVowels = /[iıöüşğçİ0123456789]/i;

    if (!e.key.match(/[a-zA-Z]/) && e.key !== " " && !turkishVowels.test(e.key)) {
        e.preventDefault();
    }
};