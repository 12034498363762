import { fetchApi } from '../index';

export const GetAverageLessonSkills = async (termId) => {

    const url = '/member/v1/lesson/get_lesson_skills_average/';

    const payload = {
        termId: termId
    };

    return await fetchApi(url, payload);
}