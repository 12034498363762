import { getCookie } from 'ko-basic-cookie';
import { cookieName } from '../consts/Constants';
import { decodeToken } from '../components/Utility';

const useAuth = () => {

    const token = getCookie(cookieName);
    const employee = token && decodeToken(token);

    return { employee, token }
}

export default useAuth