import { fetchApi } from '../index';

/**
 * Eğer bir çalışan işten ayrıldıysa durumu güncelleniyor.
 * Token cookie'de kayıtlı olduğundan bu güncelleme anlaşılmayabilir.
 * Bu fonksiyon ile çalışan her ana sayfaya geldiğinde durum kontrolü yapılıyor.
 * @param {guid} EmployeeId - Çalışan id.
 */
export const CheckEmployeeStatus = async (employeeId) => {

    const url = '/employee/v1/employee/get_status_by_employee_id/';

    const payload = {
        employeeId: employeeId
    };

    return await fetchApi(url, payload);
}

export const CompanyPhpLogin = async (email, password) => {

    const url = '/corporate/v1/company_old_system_id/company_login_by_phpapi/';

    const payload = {
        email: email,
        password: password
    };

    return await fetchApi(url, payload);
}