import { getLanguageTypeByLanguageKey } from '../../components/Utility';
import { fetchApi } from '../index';

export const GetMemberTerms = async (memberId, language) => {

    const url = '/member/v1/term/get_member_terms/';

    const payload = {
        memberId: memberId,
        languageType: getLanguageTypeByLanguageKey(language)
    };
    
    return await fetchApi(url, payload);
}

//Term - İlgili term'e ait öğrenci adını getiren servis.
export const GetTermStudentName = async (termId) => {
    const url = '/member/v1/term/get_student_name/';

    const payload = {
        termId: termId,
    };

    return await fetchApi(url, payload);
}

//Term - İlgili term'e ait dönem başlangıç tarihini getiren servis.
export const GetTermBeginDateAndDay = async (termId) => {
    const url = '/member/v1/term/get_term_begin_date_and_day/';

    const payload = {
        termId: termId
    };

    return await fetchApi(url, payload);
}

//Term - İlgili term'e ait dönem başlangıç tarihini getiren servis.
export const GetTermEndDateAndDay = async (termId) => {
    const url = '/member/v1/term/get_term_end_date_and_day/';

    const payload = {
        termId: termId
    };

    return await fetchApi(url, payload);
}


//Term - İlgili term'e ait ders geçmişini getiren servis.
export const GetLessonHistory = async (termId) => {
    const url = '/member/v1/term_schedule/term_schedule_status/';

    const payload = {
        termId: termId
    };

    return await fetchApi(url, payload);    
}

//Term - İlgili term'e ders erteleme sayısı getiren servis
export const GetPostponedLessonCount = async (termId) => {
    const url = '/member/v1/term_schedule/get_postponed_lesson_count/';

    const payload = {
        termId: termId
    };

    return await fetchApi(url, payload);
}