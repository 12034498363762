export const Local = {
    alert: {
        title: {
            information: "Bilgilendirme Mesajı"
        },
        centered_alert_two_buttons: {
            title: "Emin Misiniz?",
            title_confirm: 'İşlem Onayı',
            confirm_text: "Tamam",
            cancel_text: "İptal",
            save_question: "Değişiklikleri kaydetmek istiyor musunuz?",
            irreversible_action: "Bu işlem geri alınamayan bir işlemdir."
        },
        top_alert: {
            inform: 'Lütfen bilgileri eksiksiz giriniz!',
            success: 'İşlem başarıyla tamamlandı!'
        }
    },
    form_info: {
    },
    count: '<span class="font-bold">#count#</span> adet kayıt bulundu.',
    detail_search_titles: {
        detail_search: 'Detaylı Arama',
        choose_all: 'Tümünü Seç',
        selected_data_count: 'Seçilen Veri Sayısı',
        choose_language: "Dil Seçiniz",
        date_range: 'Tarih Aralığı Seçiniz',
    },
    button: {
        save: 'Kaydet',
        save_upper: 'KAYDET',
        cancel: 'İptal',
        cancel_upper: 'İPTAL',
        confirm: 'Onayla',
        confirm_upper: 'ONAYLA',
        create: 'Oluştur',
        clear: 'Temizle',
        update: 'Güncelle',
        update_upper: 'GÜNCELLE',
        add: 'Ekle',
        add_upper: 'EKLE',
        delete: 'Sil',
        undo: 'Geri Al',
        send: 'Gönder',
        list: 'Listele',
        browse: "Göz at",
        choose: 'Seç',
        choose_upper: 'SEÇ',
        list_upper: 'LİSTELE',
        edit: 'Düzenle',
        continue: 'Devam Et',
    },
    search_bar: {
        search: 'Ara',
        clear: 'Temizle',
    },
    pagination: {
        previous: "Geri",
        next: "İleri"
    },
    table: {
        profile_photo: 'Profil Resmi',
        profile: 'Profil',
        operation: "İşlem",
        status: "Durum",
        date: "Tarih",
        total_upper: "TOPLAM",
    },
    errors: {
        //genel hatalar
        title: "Hata",
        an_error_occurred: "İşlem başarısız oldu!",
        an_error_occurred_2: "İşlem sırasında bir hata oluştu! Lütfen tekrar deneyiniz.",
        incorrect_password_or_email: "Yanlış e-posta adresi veya şifre!",
        not_authorized: "Yetkiniz yok",
        no_records_found: "Herhangi Bir Kayıt Bulunamadı",
        not_authorized_for_system: "Sisteme giriş için yetkiniz yok!",
        not_authorized_for_this_page: "Bu sayfaya giriş için yetkiniz yok",
        not_allowed: "İşleme Kapalı",
        it_is_not_specified: "Belirtilmedi",
        it_is_not_specified_upper: 'BELİRTİLMEDİ',
        error404_title: "Sayfa Bulunamadı",
        error404_content: "Aradığınız sayfa bulunamadı. Menüleri kullanarak dilediğiniz sayfaya ulaşabilirsiniz.",
        data_not_found: "Veri bulunamadı!",
        //.... alanı gereklidir hataları
        email_error: "E-posta Adresi alanı gereklidir!",
        code_error: "Doğrulama kodu alanı gereklidir!",
        password_error: "Şifre alanı gereklidir!",
        passwords_cannot_be_different: "Şifreler aynı olmalıdır!",
        //format uygun değil hataları
        email_format_error: "E-mail formatı uygun değil.",
        phone_number_format_error: "Telefon numarası formatı uygun değil.",
        identity_number_format_error: 'TC kimlik numarası formatı uygun değil.',
        //.... boş geçilemez hataları
        general_null_error: 'Bu alan boş geçilemez',
    },
    loading: "Yükleniyor...",
    sidebar: {
        home_page: "Ana Sayfa",
        profile: {
            my_profile: "Profilim",
            logout: "Çıkış Yap"
        },
        member: {
            title: "Üye İşlemleri",
            list: "Öğrenciler"
        },
    },
    navbar: {
        search: "Ara ...",
    },
    auth: {
        login: {
            title: "Giriş",
            your_email: "E-posta Adresi",
            your_password: "Şifre",
            login_button: "Giriş Yap",
        },
    },
    dashboard: {
        welcome: "Hoşgeldin"
    },
    member: {
        student: {
            name_surname: "İsim Soyisim",
            attended_lesson_count: "Katıldığı Ders Sayısı",
            attended_rate: "Katılım Oranı",
            remaining_lesson_count: "Kalan Ders Sayısı",
            start_level: "Başlangıç Seviyesi",
            current_level: "Mevcut Seviye",
            print_to_excel: "Excel'e Çıktı Al",
            all_levels: "Tüm Seviyeler",
            click_for_all_levels: "Tüm seviyeleri görmek için tıklayınız",
            lesson_detail: {
                title: "Ders Detayları",
                speaking_grammar_usage: 'Dilbilgisi Kullanımı',
                speaking_vocabulary_usage: 'Kelime Kullanımı',
                speaking_pronunciation: 'Telaffuz',
                listening_comprehension: 'Dinlediğini Anlama',
                reading_vocabulary_awareness: 'Okuma Kelime Farkındalığı',
                reading_pronunciation: 'Okuma Telaffuz',
                click_for_lesson_detail: "Not Detayı İçin Tıklayınız",
                note_detail: 'Not Detayları',
                student_term_detail: {
                    title: "Kişi Detayları"
                },
                letter_note_informations: {
                    speaking_grammar: {
                        one: "The speaker has a very basic understanding of grammar and struggles to form coherent sentences. Errors in grammar usage are frequent and may interfere with understanding.\nMany errors in grammar usage, including frequent errors in subject-verb agreement, verb tense, and word order. The number of errors could be in the range of 10-20 per minute of speech.",
                        two_three: "The speaker has a limited but functional understanding of grammar and can communicate basic information with some errors. Grammar mistakes may still be frequent and noticeable.\nSome errors in grammar usage, including errors in basic structures such as singular and plural forms, articles, and simple verb tenses. The number of errors could be in the range of 5-10 per minute of speech.",
                        four_five: "The speaker has a good understanding of grammar and can communicate effectively, though with occasional errors. The speaker can use complex sentence structures and express ideas with relative ease.\nOccasional errors in grammar usage, including errors in complex structures such as subordinate clauses and verb tense consistency. The number of errors could be in the range of 2-5 per minute of speech.",
                        six_seven: "The speaker has a near-native command of grammar and can communicate complex ideas with little difficulty. Errors in grammar usage are rare and do not interfere with understanding.\nFew errors in grammar usage, with occasional mistakes in complex structures such as passive voice and reported speech. The number of errors could be in the range of 1-2 per minute of speech.",
                        eight_nine: "The speaker has a native-like mastery of grammar and can use language flexibly and effectively for a range of purposes. The speaker can understand and use complex structures, idioms, and other nuanced language features.\nRare errors in grammar usage, with occasional mistakes in subtle nuances such as idiomatic expressions and sentence-level discourse markers. The number of errors could be less than 1 per minute of speech.",
                        ten: "The speaker has a mastery of grammar that exceeds that of most native speakers. The speaker can use language creatively and with a high degree of precision, using a wide range of registers and styles as appropriate to the context.\nVirtually no errors in grammar usage, with occasional slips in pronunciation or vocabulary choice. The number of errors could be less than 1 per several minutes of speech."
                    },
                    speaking_vocabulary_usage: {
                        one: "The speaker has a very basic vocabulary and struggles to express ideas with limited words. They may rely on frequent repetition of the same words or phrases.\nFrequent errors in word choice, pronunciation, and usage. The speaker may rely on a small set of words and struggle to convey even basic ideas. The number of errors could be in the range of 10-20 per minute of speech.",
                        two_three: "The speaker has a functional vocabulary and can communicate basic information with some errors. However, their vocabulary may be limited to everyday words and phrases, and they may struggle with more complex or specialized terminology.\nSome errors in word choice, pronunciation, and usage. The speaker may struggle with more advanced vocabulary and rely on basic words and phrases. The number of errors could be in the range of 5-10 per minute of speech.",
                        four_five: "The speaker has a good vocabulary and can communicate effectively in most situations. They can use a range of vocabulary to express ideas, but may still struggle with more advanced terminology.\nOccasional errors in word choice, pronunciation, and usage. The speaker can use a range of vocabulary to express ideas, but may still struggle with more advanced or specialized terminology. The number of errors could be in the range of 2-5 per minute of speech.",
                        six_seven: "The speaker has a broad vocabulary and can use language flexibly and precisely to express complex ideas. They can use specialized terminology and idiomatic expressions appropriately in a range of contexts.\nFew errors in word choice, pronunciation, and usage. The speaker can use language flexibly and precisely to express complex ideas, and can understand and use specialized terminology appropriately. The number of errors could be in the range of 1-2 per minute of speech.",
                        eight_nine: "The speaker has an extensive and nuanced vocabulary, and can use language creatively to achieve a range of communicative goals. They can understand and use complex vocabulary in a variety of registers and styles.\nRare errors in word choice, pronunciation, and usage. The speaker has an extensive vocabulary and can use language creatively and effectively, even in specialized or formal contexts. The number of errors could be less than 1 per minute of speech.",
                        ten: "The speaker has a near-native command of vocabulary, using language with an exceptional range of precision and nuance. They can understand and use rare or archaic vocabulary, as well as complex idiomatic expressions, with ease.\nVirtually no errors in word choice, pronunciation, and usage. The speaker has an exceptional range of vocabulary and can use language with an exceptional degree of precision and nuance. The number of errors could be less than 1 per several minutes of speech."
                    },
                    speaking_pronunciation: {
                        one: "The speaker is difficult to understand, with poor articulation and significant errors in pronunciation, stress, and intonation. They may struggle to produce even basic sounds and may not be able to be understood by native speakers.\nThe speaker may make numerous mistakes in pronunciation, such as mispronouncing many sounds, omitting or adding sounds, and using incorrect stress and intonation patterns.",
                        two_three: "The speaker can produce some sounds and words correctly, but may still have difficulty with more complex sounds and pronunciations. Their intonation and stress may be off, making it difficult to understand them in certain contexts.\nThe speaker may still make some noticeable errors in pronunciation, such as mispronouncing some sounds or having trouble with more complex sounds, but can generally be understood by others.",
                        four_five: "The speaker can produce most sounds and words accurately and has a good grasp of basic intonation and stress patterns. They may still make some errors, particularly with less common sounds or words, but can generally be understood by native speakers.\nThe speaker may make occasional errors in pronunciation, such as mispronouncing some less common words or having a slight accent, but can generally communicate fluently and accurately.",
                        six_seven: "The speaker can produce nearly all sounds and words accurately and has a good command of intonation and stress patterns, making them sound more natural and fluent. They may still have some slight accent or occasional errors, but overall can be easily understood by native speakers.\nThe speaker may make few errors in pronunciation, and can generally produce all English sounds with accuracy and natural intonation and stress. They may still have a slight accent, but it does not interfere with their communication.",
                        eight_nine: "The speaker can produce all sounds and words accurately, with near-native pronunciation and intonation. They are able to make fine distinctions in sound and can adapt their pronunciation to different dialects and contexts. They may still have a slight accent, but are generally indistinguishable from native speakers.\nThe speaker may make very few errors in pronunciation, and can produce all English sounds with near-native accuracy and natural intonation and stress. They may still have a slight accent, but it is difficult to detect.",
                        ten: "The speaker has native-level pronunciation and intonation, with no discernible accent. They are able to produce subtle distinctions in sound and can adapt their pronunciation to any dialect or context. They may also be able to imitate different accents or dialects with ease.\nThe speaker is unlikely to make any noticeable errors in pronunciation, and can produce all English sounds with native-like accuracy and natural intonation and stress. They may be mistaken for a native speaker."
                    },
                    listening_comprehension: {
                        one: "The listener has difficulty understanding even basic words and phrases in the language. They may need to rely on visual cues or context to understand the meaning of spoken language.\nThe listener may make numerous mistakes, such as not being able to identify basic vocabulary or verb forms, and having difficulty understanding even simple sentences or phrases.",
                        two_three: "The listener can understand simple words and phrases, but may struggle with more complex sentences or idiomatic expressions. They may need to ask for repetition or clarification in order to understand the meaning of spoken language.\nThe listener may make some mistakes, particularly with more complex language or idiomatic expressions, but can generally understand the meaning of spoken language with repetition or clarification.",
                        four_five: "The listener can understand most spoken language in familiar contexts, but may still struggle with more complex or abstract language, particularly when it is spoken quickly or with an unfamiliar accent.\nThey may also miss some details or nuances in the language. The listener may make occasional mistakes, such as missing some details or nuances in the language, particularly when it is spoken quickly or with an unfamiliar accent.",
                        six_seven: "The listener can understand most spoken language, including more complex and abstract language, spoken at a normal pace and with a variety of accents. They can also understand implied meanings and cultural references.\nThe listener may make few mistakes and can understand most spoken language, including more complex and abstract language, spoken at a normal pace and with a variety of accents.",
                        eight_nine: "The listener can understand spoken language with a high degree of accuracy and fluency, even in complex or technical contexts. They can also understand nuances in tone, register, and intonation.\nThe listener may make very few mistakes, and can understand spoken language with a high degree of accuracy and fluency, even in complex or technical contexts.",
                        ten: "Exceptional proficiency: The listener can understand spoken language with complete accuracy and fluency, even in the most challenging contexts, such as fast-paced conversations between multiple speakers or with strong regional accents. They can also understand nuances in meaning and cultural references at a level that is equivalent to that of a native speaker.\nThe listener is unlikely to make any mistakes and can understand spoken language with complete accuracy and fluency, even in the most challenging contexts."
                    },
                    reading_vocabulary_awareness: {
                        one: "The reader has a very limited vocabulary and struggles to understand even basic words in the language. They may need to rely heavily on a dictionary or translation tool to read simple texts.\nThe reader may make numerous mistakes, such as not recognizing basic words or being unable to infer the meaning of unfamiliar words from context.",
                        two_three: "The reader has a basic vocabulary and can understand simple texts with familiar vocabulary, but may struggle with more complex or technical vocabulary.\nThe reader may make some mistakes, particularly with less familiar vocabulary, but can generally understand most of the words in simple texts.",
                        four_five: "The reader has a moderate vocabulary and can understand most texts with familiar vocabulary, but may struggle with more advanced or specialized vocabulary.\nThe reader may make occasional mistakes, such as misunderstanding the meaning of more complex or technical vocabulary, but can generally understand most of the words in moderately difficult texts.",
                        six_seven: "The reader has an extensive vocabulary and can understand most texts with a high degree of accuracy, including those with advanced or specialized vocabulary.\nThe reader may make few mistakes, and can generally understand most of the words in difficult or specialized texts, but may occasionally need to look up unfamiliar vocabulary.",
                        eight_nine: "The reader has an exceptional vocabulary and can understand texts with a high degree of precision, even in very specialized fields or contexts.\nThe reader may make very few mistakes, and can understand most texts with a high degree of accuracy, including those with advanced or technical vocabulary.",
                        ten: "The reader has a mastery of vocabulary in the language equivalent to that of a native speaker, and can understand and use highly nuanced and idiomatic language with ease. They may also be able to accurately and precisely infer meaning from context, without needing to rely on a dictionary or other tools.\nThe reader is unlikely to make any mistakes, and can understand and use highly nuanced and idiomatic language with ease. They may also be able to accurately and precisely infer meaning from context, without needing to look up unfamiliar vocabulary."
                    },
                    reading_pronunciation: {
                        one: "The reader reads slowly and may struggle with basic comprehension, needing to sound out words and reread frequently. Their reading may be halting and choppy, with little or no attention to expression.\nThe reader may make numerous mistakes, such as mispronouncing many words, misreading or skipping words, and struggling to understand basic vocabulary and sentence structure.",
                        two_three: "The reader reads with some fluency and is able to understand simple texts with basic vocabulary. Their reading may still be slow and require occasional assistance.\nThe reader may make some mistakes, such as mispronouncing some words, stumbling over more complex vocabulary, and needing some guidance to understand the text.",
                        four_five: "The reader reads with moderate fluency and is able to understand moderately complex texts with some unfamiliar vocabulary. They may still need to reread some sections for better comprehension.\nThe reader may make occasional mistakes, such as mispronouncing some specialized vocabulary, missing some nuances or idioms, and occasionally needing to reread sections for better comprehension.",
                        six_seven: "The reader reads with good fluency and is able to understand difficult texts with specialized vocabulary. They can read smoothly and efficiently with little difficulty.\nThe reader may make few mistakes, and can generally understand and pronounce most words with accuracy, but may occasionally struggle with highly specialized or technical vocabulary.",
                        eight_nine: "The reader reads with exceptional fluency and can easily understand even the most difficult texts with advanced vocabulary. They can read with excellent speed and accuracy and comprehend at a high level.\nThe reader may make very few mistakes, and can accurately and fluently read and understand texts with advanced or specialized vocabulary, without needing to look up unfamiliar words.",
                        ten: "The reader reads with near-native fluency and can read and comprehend texts as well as or better than native speakers. They can easily read a wide range of texts with advanced vocabulary and high complexity.\nThe reader is unlikely to make any mistakes, and can accurately and fluently read and understand highly nuanced and idiomatic language with ease. They may also be able to infer meaning from context with a high degree of accuracy."
                    }
                }
            }
        },
        member_detail: {
            terms: {
                term_start_date: 'Dönem Başlangıç Tarihi',
                package_name: 'Paket İsmi',
                term_end_date: 'Dönem Bitiş Tarihi',
                student_name: 'Öğrenci Adı',
                total_lesson: 'Toplam Ders',
                postpone_lesson_right: 'Ders Erteleme Sayısı',
                attended_lesson_count: 'Katıldığı Ders Sayısı',
                remaining_lesson_count: 'Kalan Ders Sayısı',
                freeze_lesson_count: 'Ders Dondurma Hakkı',
                term_status: 'Dönem Durumu',
                level: 'Seviye',
                last_subject: 'İşlenen Son Konu',
                lesson_history: 'Ders Geçmişi',
                modal: {
                    select_start_end_hour: 'Başlangıç ve Bitiş Saati Seçiniz',
                    term_begin_date_success: 'Dönem başlangıç tarihi başarıyla güncellendi!',
                    student_name_success: 'Öğrenci ismi başarıyla güncellendi!',
                }
            }
        },
    }
}