import classNames from "classnames";
import {useLocalization} from "../../../../../hooks/useLocalization";

const LevelsModal = ({ selectedLevel }) => {

    const strings = useLocalization();
    const Levels = [
        'Starter-1',
        'Beginner-1',
        'Beginner-2',
        'Beginner-3',
        'Beginner-4',
        'Intermediate-1',
        'Intermediate-2',
        'Intermediate-3',
        'Intermediate-4',
        'Intermediate-5',
        'Intermediate-6',
        'Intermediate-7',
        'Intermediate-8',
        'Intermediate-9',
        'Advanced-1',
        'Advanced-2'
    ]

    return (
        <div className="flex flex-col gap-2">
            <h3 className="font-bold text-center">{strings.member.student.all_levels}</h3>
            <div>
                {Levels.map((level, index) => (
                    <p
                        key={index}
                        className={classNames( "py-1 leading-5", {
                            "bg-blue text-white -mx-6 px-6" : selectedLevel === level
                        })}
                    >
                        {level}
                    </p>
                ))}
            </div>
        </div>
    )
}

export default LevelsModal;