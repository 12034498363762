import React from "react";
import { Outlet } from "react-router-dom";
import {KoCorporateLogo} from "../../assets/img";

const AuthLayout = () => {
  return (
    <>
        {/**/}
        <div className="flex flex-col items-center justify-center h-screen bg-white">
            <img src={KoCorporateLogo} width={230} height={230} alt="ko-corporate-logo" className="-mt-[80px]"/>
            <Outlet/>
        </div>
    </>
  );
};

export default AuthLayout;
