import {useNavigate, useParams} from "react-router-dom";
import {useEffect} from "react";
import {CompanyPhpLogin} from "../../services/Auth/AuthService";
import {employeeLogin} from "../../store/EmployeeSlice";
import {url} from "../../routes/utility";
import {showTopAlert} from "../../components";
import {useDispatch} from "react-redux";
import {useLocalization} from "../../hooks/useLocalization";

const AutoLogin = () => {

    const { base64 } = useParams();

    const strings = useLocalization();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        //Base 64 ayrıştırılıyor.
        let decodedStr = atob(base64);

        const keys = decodedStr.split('&');

        const result = {};

        keys.map(pair => {
            const [key, value] = pair.split('=');
            result[key] = value;
        });
        //Base 64 ayrıştırılıyor.

        if(result.email.length > 0 && result.pass.length > 0){
            CompanyPhpLogin(result.email, result.pass)
                .then(result => {
                    let resultContent = JSON.parse(result);

                    if(resultContent.status === 200) {
                        const token = resultContent.content;

                        dispatch(employeeLogin({ token }));
                        navigate(url("student"));
                    } else {
                        showTopAlert(strings.errors.incorrect_password_or_email, "error");
                        navigate(url("auth.login"));
                    }
                })
                .catch(e => {})
        } else {
            showTopAlert(strings.errors.an_error_occurred_2, "error");
            navigate(url("auth.login"));
        }
    }, []);
}

export default AutoLogin