import {useLocalization} from "../../../../hooks/useLocalization";

const LetterNoteInformationModal = (props) => {

    const { modalMessage } = props;
    const strings = useLocalization();

    return (
        <div className="flex flex-col text-start gap-5">
            <div className="flex justify-center gap-1">
                <p className="font-bold text-[15px]">
                    {strings.member.student.lesson_detail.note_detail}
                </p>
            </div>
            <p>{modalMessage}</p>
        </div>
    )
}

export default LetterNoteInformationModal;