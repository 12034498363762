export const fetchHeader = payload => {

    const language = JSON.parse(localStorage.getItem('language')) || 'tr';

    return {
        method: "POST",
        mode: 'cors',
        body: JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Accept-Language': language === 'tr' ? 'tr-TR' : 'en-US',
            'Source': 'CorporateCompanyPrime',
            'Project-Id': '7'
        }
    }
};