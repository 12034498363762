import React from "react";
import {isDisabled} from "@testing-library/user-event/dist/utils";

const Input = React.forwardRef((props, ref) => {

    const classname = typeof props.classnames === "undefined" ? '' : props.classnames;

    return(
        <input 
            id={props.id}
            ref={ref}
            type={props.type}
            name={props.name}
            value={props.value}
            disabled={props.isDisabled}
            min={props.minValue}
            max={props.maxValue}
            minLength={props.minLength}
            maxLength={props.maxLength}
            defaultValue={props.defaultValue}
            placeholder={props.placeholder} 
            className={`form-input w-full placeholder:!text-[13px] ${classname} ${props.isDisabled ? "opacity-70" : ""}`}
            onChange={props.onChange}
            onKeyDown={props.onKeyDown}
            readOnly={props.isReadOnly}
            onClick={props.action}
            onFocus={props.onFocus}
        />
    );
})
        
export default Input