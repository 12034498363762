export const cookieName = process.env.REACT_APP_JWT_COOKIE_NAME;

export const ServiceErrorMessages = Object.freeze(
    {
        //Company
        ANY_MEMBERS_NOT_FOUND: "Any members not found!"
    }
)

/* ---------------------------------------------------------------------------- */
export const DateRanges = [
    {id: 1, name: 'Son 1 hafta'},
    {id: 2, name: 'Son 1 ay'},
    {id: 3, name: 'Son 1 yıl'},
    {id: 4, name: 'Belirli bir tarih aralığı seç'},
];

export const TermStatusName = [
    {id: 0, en: "Passive", tr: "Pasif", az: "Pasif", ar: "Pasif"},
    {id: 1, en: "Active", tr: "Aktif", az: "Aktif", ar: "Aktif"},
    {id: 2, en: "Future", tr: "Gelecek", az: "Gelecek", ar: "Gelecek"},
    {id: 3, en: "Deleted", tr: "Silinmiş", az: "Silinmiş", ar: "Silinmiş"},
    {id: 5, en: "Pending", tr: "Onay Bekleyen", az: "Onay Bekleyen", ar: "Onay Bekleyen"},
];


