import React, {useEffect, useState} from "react";
import {Loading, NoRecordsFound, showTopAlert} from "../../components";
import {useLocalization} from "../../hooks/useLocalization";
import {StudentDetailSearch, StudentTable} from "./components";
import useAuth from "../../hooks/useAuth";
import {ExcelLogo} from "../../assets/svg";
import * as XLSX from 'xlsx';
import {fullDateToShortDate, stringToDate} from "../../components/Utility";
import {GetCompanyMemberReport} from "../../services/Company/CompanyService";
import {ServiceErrorMessages} from "../../consts/Constants";

const Student = () => {

    const strings = useLocalization();
    const { employee } = useAuth();

    //TODO: Dinamik olarak dosya adına company name verilebilir.
    let EXCEL_FILE_NAME= `Ogrenciler_Listesi.xlsx`;
    let EXCEL_SHEET_NAME = `Ogrenciler Listesi`;

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const [errorMessage, setErrorMessage] = useState("");

    const [detailSearch, setDetailSearch] = useState({
        beginDate: null,
        endDate: null
    });

    //select değişiklikleri bu alandan yönetiliyor
    const changeDetail = (values) => {
        setDetailSearch({ ...detailSearch, ...values });
    }

    const exportToExcel = (data, fileName, sheetName) => {
        if (data.corporateMemberReportListModel.length > 0) {
            let headers = ['İsim Soyisim', 'Katıldığı Ders Sayısı', 'Mevcut Seviye', 'Başlangıç Seviyesi',
                'Katılım Oranı', 'Kalan Ders Sayısı'];

            // Hücrenin en altında toplam verileri görünmesi için veri manipüle ediliyor
            let totalObject = {
                memberName: "TOPLAM",
                attendedLessonCount: data?.corporateMemberTotalReportModel.totalAttendedLessonCount,
                firstLessonLevel: "-",
                currentLevel: "-",
                attendanceRate: data?.corporateMemberTotalReportModel.averageAttendanceRate,
                remainingLessonCount: data?.corporateMemberTotalReportModel.totalRemainingLessonCount
            }

            // toplam verisi ile liste birleştiriliyor
            let newData = [...data?.corporateMemberReportListModel, totalObject];

            // memberId'ı, termId, stat veriden kaldır
            const filteredData = newData.map(({ memberId, termId, status, ...rest }) => rest);

            // Verileri ve başlıkları birleştirir
            const finalData = [headers, ...filteredData.map(Object.values)];

            // Verileri Excel sayfasına dönüştür
            const ws = XLSX.utils.aoa_to_sheet(finalData);

            // A'dan F'ye kadar olan sütunların genişliğini 25 olarak ayarlar
            ws['!cols'] = [
                { wch: 25 }, // A
                { wch: 25 }, // B
                { wch: 25 }, // C
                { wch: 25 }, // D
                { wch: 25 }, // E
                { wch: 25 }, // F
            ];

            // İlk satırın yüksekliğini 25 olarak ayarlar
            ws['!rows'] = [{ hpt: 25 }];

            // Yeni bir Excel çalışma kitabı oluşturur
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, sheetName || 'Sheet 1');

            // Dosyayı indirir
            XLSX.writeFile(wb, fileName || 'exported_data.xlsx');
        } else {
            showTopAlert(strings.errors.data_not_found, "info");
        }
    };

    const getCompanyMemberReport = async () => {
        let model = {
            companyId : employee.companyId,
            beginDate: detailSearch.beginDate ? fullDateToShortDate(stringToDate(detailSearch.beginDate)) : null,
            endDate: detailSearch.endDate ? fullDateToShortDate(stringToDate(detailSearch.endDate, 23, 59, 59)) : null,
        }

        const result = await GetCompanyMemberReport(model);

        if(result.status === 200) {
            let resultContent = JSON.parse(result.content);
            setData(data => resultContent);
        } else {
            setErrorMessage(strings.errors.an_error_occurred_2);
        }

        setLoading(loading => false);
    }

    useEffect(() => {

        let abortController = new AbortController();

        setErrorMessage(errorMessage => "");
        setData(data => {})
        setLoading(loading => true);
        getCompanyMemberReport();

        return () => {
            abortController.abort();
        }
    }, [detailSearch])

    return (
        <>

            <div className="flex gap-2 md:gap-5 items-start">
                <div className="w-full">
                    <StudentDetailSearch
                        changeDetail={changeDetail}
                        loading={loading}
                    />
                </div>
                <button
                    type="button"
                    className="group relative mt-4"
                    disabled={loading}
                    onClick={() => exportToExcel(data, EXCEL_FILE_NAME, EXCEL_SHEET_NAME)}
                >
                    <img src={ExcelLogo} alt="excel-logo" width={30} height={30}/>
                    <div className="tooltip-top">
                        <p>{strings.member.student.print_to_excel}</p>
                    </div>
                </button>
            </div>

            {loading && <Loading/>}

            {!loading && (
                <StudentTable
                    data={data?.corporateMemberReportListModel}
                    total={data?.corporateMemberTotalReportModel}
                    errorMessage={errorMessage}
                />
            )}

            <div id="company-modal-container"></div>
        </>
    );
};

export default Student;
