import React from "react";
import { useLocalization } from "../hooks/useLocalization";

const Loading = () => {

  const strings = useLocalization();

  return (
    <div className="flex gap-2 items-center justify-center mt-5">
      <div className="inline-block h-4 w-4 animate-spin rounded-full border-[3px] border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"></div>
      <p>{strings.loading}</p>
    </div>
  );
};

export default Loading;
