import {Loading, PageTitle} from "../../../../components";
import {useLocalization} from "../../../../hooks/useLocalization";
import {GetMemberTerms} from "../../../../services/Term/TermService";
import {useEffect, useState} from "react";
import TermContent from "../Common/TermContent";

const StudentTermDetail = ({ memberId, termId }) => {

    const strings = useLocalization();

    const [termData, setTermData] = useState({});
    const [termLoading, setTermLoading] = useState(true);

    //Member a ait dönem bilgileri getiriliyor
    const getMemberTerms = async (memberId, language) => {
        const result = await GetMemberTerms(memberId, language);

        if (result.status === 200) {
            //Term listei alınıyor
            let terms = JSON.parse(result.content);

            let filteredTerm = terms.find(term => term.termId === termId);
            setTermData(filteredTerm);
            setTermLoading(termLoading => false);
        }
    }

    useEffect(() => {
        if(memberId && termId) {
            getMemberTerms(memberId, termId, "tr");
        }
    }, [memberId, termId])

    return (
        <>
            <PageTitle title={strings.member.student.lesson_detail.student_term_detail.title} />
            <div className="bg-white border border-[#e7e7e7] px-2 xs:px-[15px] py-[10px] rounded-md text-center">
                <div className="mb-3">
                    {termLoading ? (
                        <Loading />
                    ) : (
                        <TermContent termDetail={termData} />
                    )}
                </div>
            </div>
        </>
    )
}

export default StudentTermDetail;