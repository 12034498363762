import {useLocalization} from "../../hooks/useLocalization";
import {useNavigate} from 'react-router-dom';
import React, {useRef, useState} from "react";
import {url} from "../../routes/utility";
import {useDispatch} from "react-redux";
import {employeeLogin} from "../../store/EmployeeSlice";
import {showTopAlert} from "../../components";
import {validateEmail} from "../../components/Utility";
import {CompanyPhpLogin} from "../../services/Auth/AuthService";

const Login = () => {
    const strings = useLocalization();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const inputRef = useRef();
    const visibilityRef = useRef();
    const visibilityOffRef = useRef();
    const emailErrorRef = useRef();
    const passwordErrorRef = useRef();
    const emailHandle = e => {
        setEmail(email => e.target.value);
    };

    const passwordHandle = e => {
        setPassword(password => e.target.value);
    };

    const checkPassword = pass => {
        return (pass.length >= 4);
    }

    const togglePassword = () => {
        visibilityRef.current.classList.toggle('hidden');
        visibilityOffRef.current.classList.toggle('hidden');
        inputRef.current.type = inputRef.current.type === "password" ? "text" : "password"
    }

    const submitHandle = async e => {
        e.preventDefault();
        setLoading(loading => true);

        //tüm hataları sıfırla
        passwordErrorRef.current.innerText = '';
        emailErrorRef.current.innerText = '';

        const emailStatus = validateEmail(email);
        const passwordStatus = checkPassword(password);

        if (emailStatus && passwordStatus) {

            const result = await CompanyPhpLogin(email, password);
            let resultContent = JSON.parse(result);

            //Giriş başarılı ise
            if (resultContent.status === 200) {
                const token = resultContent.content;

                // Login işlemini gerçekleştir
                dispatch(employeeLogin({ token }));
                navigate(url("student"));
            } else {
                //Giriş başarısız ise
                showTopAlert(strings.errors.incorrect_password_or_email, "error");
            }
        } else {
            // hata varsa ekrana yaz
            passwordErrorRef.current.innerText = !passwordStatus ? strings.errors.password_error : '';
            emailErrorRef.current.innerText = !emailStatus ? strings.errors.email_error : '';
        }

        setLoading(loading => false);
    }

    return (
        <>
            <form className="w-full flex justify-center" onSubmit={submitHandle}>
                <div
                    className="w-[400px] animate-fadeIn p-[25px] md:py-[60px] rounded-[5px] block bg-white border border-[#ebecec] shadow-form mx-5 mt-5 md:mt-0">
                    <h3 className="font-bold text-center text-[19px] md:mb-[25px]">{strings.auth.login.title}</h3>
                    <div className="login-form">
                        <div className="flex flex-col px-[10px] pb-2">
                            <label className="text-[85%]" htmlFor="email" >{strings.auth.login.your_email}</label>
                            <input id="email" type="text" className="form-input" name="email" onChange={emailHandle} />
                            <span ref={emailErrorRef} className="text-danger field-validation-valid"></span>
                        </div>
                        <div className="flex flex-col px-[10px] pt-[5px] relative">
                            <label className="text-[85%]" htmlFor="password">{strings.auth.login.your_password}</label>
                            <input ref={inputRef} id="password" type="password" className="form-input pr-10" name="password" onChange={passwordHandle} />
                            <span ref={visibilityRef} onClick={togglePassword}
                                className="toggle-password mt-[11px] material-symbols-outlined absolute right-5 top-[43px] -translate-y-1/2 text-xl cursor-pointer">
                                visibility
                            </span>
                            <span ref={visibilityOffRef} onClick={togglePassword}
                                className="hidden toggle-password mt-[11px] material-symbols-outlined absolute right-5 top-[43px] -translate-y-1/2 text-xl cursor-pointer">
                                visibility_off
                            </span>
                            <span ref={passwordErrorRef} className="text-danger field-validation-valid" ></span>
                        </div>
                        <div className=" px-[10px] pt-[10px] md:pt-[25px]">
                            <button
                                type="submit"
                                className={`${loading ? "!opacity-[70%] cursor-progress" : ""} btn btn-primary w-full`}
                            >
                                {loading && (
                                    <div
                                        className="inline-block h-4 w-4 animate-spin rounded-full border-[3px] border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"></div>
                                )}
                                {!loading && (
                                    strings.auth.login.login_button
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default Login