import { useEffect, useRef, useState } from "react";
import { DetailSearchLayout, Dropdown } from "../../../../components";
import { useLocalization } from "../../../../hooks/useLocalization";
import {getDateWithDayDifference, getddMMyyyyDate} from "../../../../components/Utility";
import {DateRanges} from "../../../../consts/Constants";
import DatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import tr from "date-fns/locale/tr";

const StudentDetailSearch = ({ changeDetail, loading }) => {

  registerLocale("tr", tr);
  const strings = useLocalization();

  //Bu bölüm belirli tarih aralığı seçmek için kullanılan değişkenlerin/fonksiyonların olduğu bölüm
  const datePickerRef = useRef();
  const [beginDate, setBeginDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleDateRange = (dates) => {
    const [start, end] = dates;
    setBeginDate(start);
    setEndDate(end);
    if (start !== null && end !== null) {
      let tmpDateRange = { id: 5, name: getddMMyyyyDate(start) + " - " + getddMMyyyyDate(end) };
      dateRangeList.push(tmpDateRange);
      changeDetail?.({ beginDate: getddMMyyyyDate(start), endDate: getddMMyyyyDate(end) });
      setBeginDate(null);
      setEndDate(null);
      setDateRange(dateRange => dateRangeList[5]);
      datePickerRef.current.classList.add("hidden");
    }
  };

  const firstDateRange = { id: -1, name: strings.detail_search_titles.date_range };
  const dateRangeList = [firstDateRange, ...DateRanges];

  const [dateRange, setDateRange] = useState(firstDateRange);
  const firstVisit = useRef(true);

  useEffect(() => {
    //Sayfa ilk kez yüklenmiyorsa işlemi yap
    //Servise birden çok gez gitmesini engelliyoruz
    if (!firstVisit.current) {
      let todayDate = new Date();
      let tmpBeginDate = '';
      let tmpEndDate = '';

      datePickerRef.current.classList.add("hidden");

      switch (dateRange.id) {
        case 1:
          //Son 7 gün seçildiyse
          tmpEndDate = getddMMyyyyDate(todayDate);
          tmpBeginDate = getddMMyyyyDate(getDateWithDayDifference(todayDate, 6));
          break;
        case 2:
          //Son 1 ay seçildiyse
          tmpEndDate = getddMMyyyyDate(todayDate);
          tmpBeginDate = getddMMyyyyDate(getDateWithDayDifference(todayDate, 29));
          break;
        case 3:
          //Son 1 yıl seçildiyse
          tmpEndDate = getddMMyyyyDate(todayDate);
          tmpBeginDate = getddMMyyyyDate(getDateWithDayDifference(todayDate, 364));
          break;
        case 4:
          //Tarih aralığı seçildiyse
          datePickerRef.current.classList.remove('hidden');
          break;
      }

      (dateRange.id < 4) && changeDetail?.({ beginDate: tmpBeginDate, endDate: tmpEndDate });

      firstVisit.current = false;
    }
  }, [dateRange])

  useEffect(() => {
    //Sayfa yüklendikten sonra false yaparak her değişiklikte
    //servisin ziyaret edilmesini sağlıyoruz
    firstVisit.current = false;
  }, [])

  return (
      <>
        <DetailSearchLayout
            isDisabled={loading}
            children={
              <div className="!flex gap-x-4 gap-y-5 max-md-lg:justify-center flex-wrap relative">
                <Dropdown
                    title={strings.detail_search_titles.date_range}
                    data={dateRangeList}
                    selectedData={dateRange}
                    setSelectedData={setDateRange}
                    isDisabled={loading}
                />
                <div ref={datePickerRef} className='absolute top-[75px] z-10 hidden'>
                  <button className='absolute button search-button !h-[25px] !p-2 top-[-28px] bg-red text-white focus:!border-none right-0'
                          onClick={() => datePickerRef.current.classList.add("hidden")}>X</button>
                  <DatePicker
                      locale="tr"
                      dateFormat="dd/MM/yyyy"
                      selected={beginDate}
                      onChange={handleDateRange}
                      onClickOutside={() => datePickerRef.current.classList.add("hidden")}
                      startDate={beginDate}
                      endDate={endDate}
                      selectsRange
                      inline
                  />
                </div>
              </div>
            }
        />
      </>
  )
};

export default StudentDetailSearch;
