import AuthRoute from "../pages/AuthRoute";
import { CheckAuth } from "../pages/Redirect";
import { AuthLayout, Login } from "../pages/Auth";
import { ErrorLayout } from "../pages/Error404";
import {StudentLayout, Student, StudentDetail} from "../pages/Student";
import AutoLogin from "../pages/Auth/AutoLogin";

const routes = [

    {
        name: "home",
        path: "/",
        roles: [],
        element: <CheckAuth />,
    },
    {
        name: "autologin",
        path: "autologin/:base64",
        roles: [],
        element: <AutoLogin />
    },
    {
        name: "auth",
        path: "auth",
        roles: [],
        element: <AuthLayout />,
        children: [
            {
                index: true,
                element: <Login />
            },
            {
                name: "login",
                path: "login",
                roles: [],
                element: <Login />
            }
        ]
    },
    {
        name: "student",
        path: "student",
        auth: true,
        roles: [],
        element: <StudentLayout />,
        children: [
            {
                index: true,
                name: "student",
                auth: true,
                roles: [],
                element: <Student />
            },
            {
                name: "detail",
                path: "detail/:memberId",
                auth: true,
                roles: [],
                element: <StudentDetail />
            }
        ]
    },
    {
        name: "error404",
        path: "*",
        auth: true,
        roles: [],
        element: <ErrorLayout />
    }
]

const authMap = routes => routes.map(route => {
    if (route?.auth) {
        route.element = <AuthRoute>{route.element}</AuthRoute>;
    }
    if (route?.children) {
        route.children = authMap(route.children)
    }
    return route
})

export default authMap(routes);