import { fetchApi } from '../index';

export const GetCompanyMemberReport = async (model) => {

    const url = '/corporate/v1/company_member/get_company_member_report/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}