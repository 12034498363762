import React from "react";
import { url } from "../../../routes/utility";
import { NavLink, useLocation } from "react-router-dom";
import { useLocalization } from "../../../hooks/useLocalization";
import { MemberItems } from "../../../consts/menu/SidebarMenu";
import classNames from "classnames";
import useMobileView from "../../../hooks/useMobileView";

const MemberOperation = () => {

    const strings = useLocalization();
    const location = useLocation();
    const path = location?.pathname;
    const { isMobileView } = useMobileView();

    const MEMBER_OPERATION_ITEMS = MemberItems(strings);

    return (
        <>
            {MEMBER_OPERATION_ITEMS.map((item, index) => (
                <NavLink
                    key={index}
                    to={url(item.pathName)}
                    className={({ isActive }) =>
                        classNames("sidebar-buttons group", {
                            "active-button": isActive,
                            "!justify-end": !isMobileView
                        })
                    }
                    onClick={() => {
                        if (path === item.path) {
                            window.location.href = `${item.path}`;
                        }
                    }}
                >
          <span className="material-symbols-outlined mr-3 flex-shrink-0 h-6 w-6">
            {item.icon}
          </span>
                    <span className="toggled w-full">{item.title}</span>
                </NavLink>
            ))}
        </>
    );
};

export default MemberOperation;
