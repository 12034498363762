import {url} from "../../../../routes/utility";
import {useLocalization} from "../../../../hooks/useLocalization";
import {Link} from "react-router-dom";
import {convertStringToPercentageFormat, isItSpecified} from "../../../../components/Utility";
import classNames from "classnames";
import {Modal, Portal} from "../../../../components";
import React, {useState} from "react";
import LevelsModal from "../StudentDetail/Modals/LevelsModal";

const StudentTableRow = ({member}) => {

    const strings = useLocalization();
    const notSpecified = strings.errors.it_is_not_specified;

    const [modal, setModal] = useState(false);
    const [modalContent, setModalContent] = useState({});

    return (
        <>
            <tr className="bg-white hover:bg-[#f0f0f0]">
                <td className="td align-middle py-4">
                    <div className="flex items-center gap-1">
                        <span className="material-symbols-outlined text-black">person</span>
                        <Link
                            to={url("student.detail", {memberId: member.memberId})}
                            state={{termId: member.termId}}
                            className="font-bold text-blue hover:underline focus:underline min-w-max"
                        >
                            <p className="min-w-max">{member.memberName}</p>
                        </Link>
                    </div>
                </td>
                <td className="td align-middle">
                    {member.attendedLessonCount}
                </td>
                <td className="td align-middle">
                    {convertStringToPercentageFormat(member.attendanceRate)}
                </td>
                <td className="td align-middle">
                    {member.remainingLessonCount}
                </td>
                <td className="td align-middle">
                    <div
                        className={classNames("relative group max-w-max", {
                                "hover:font-bold hover:underline hover:text-blue cursor-pointer": member.firstLessonLevel
                            }
                        )}
                        onClick={() => {
                            if(member?.firstLessonLevel) {
                                setModal(true);
                                setModalContent({ element: "levels" });
                            }
                        }}
                    >
                        {isItSpecified(member.firstLessonLevel, notSpecified)}
                        {member.firstLessonLevel && (
                            <p className="tooltip-top">
                                {strings.member.student.click_for_all_levels}
                            </p>
                        )}
                    </div>
                </td>
                <td className="td align-middle">
                    {isItSpecified(member.currentLevel, notSpecified)}
                </td>
            </tr>

            <Portal container={document.getElementById('company-modal-container')}>
                {(Object.keys(modalContent).length !== 0 && (
                    <Modal
                        modal={modal}
                        setModal={setModal}
                        classes={{ modal: 'h-[520px] max-w-[350px]' }}
                    >
                        {modalContent.element === "levels" &&
                            <LevelsModal
                                selectedLevel={member?.firstLessonLevel}
                            />
                        }
                    </Modal>
                ))}
            </Portal >
        </>
    )
}

export default StudentTableRow;