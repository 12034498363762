import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Input, LessonTime, Modal} from "../../../../components";
import {TermStatusName} from "../../../../consts/Constants";
import {iconForLessonStatus, ISOStringToDate2, translateWeekDays} from "../../../../components/Utility";
import {
  GetLessonHistory, GetPostponedLessonCount,
  GetTermBeginDateAndDay,
  GetTermEndDateAndDay,
  GetTermStudentName
} from "../../../../services/Term/TermService";
import {useLocalization} from "../../../../hooks/useLocalization";

const TermContent = (props) => {

  const { termDetail } = props;
  const strings = useLocalization();
  const language = useSelector(state => state.localizationStore.language);

  const notSpecified = strings.errors.it_is_not_specified;
  const termStatus = TermStatusName.find(status => status.id === termDetail.status);

  const [termStartDate, setTermStartDate] = useState(new Date());
  const [termStartText, setTermStartText] = useState("");

  const [termEndDate, setTermEndDate] = useState(new Date());
  const [termEndText, setTermEndText] = useState("");

  const [studentName, setStudentName] = useState('');

  const [postponedLessonCount, setPostponedLessonCount] = useState(0);

  const [lessonHistory, setLessonHistory] = useState([]);

  //Dönem başlangıç tarihi için servis
  const getTermBeginDateAndDay = async (termId) => {
    const result = await GetTermBeginDateAndDay(termId);

    if (result.status === 200) {
      let resultContent = JSON.parse(result.content);

      let tmpText = ISOStringToDate2(resultContent.date) + " / " + translateWeekDays(resultContent.dayOfDate, language);
      setTermStartText(termStartText => tmpText);
      setTermStartDate(termStartDate => new Date(resultContent.date));
    } else {
      setTermStartDate(termStartDate => new Date(termDetail.beginDate));
      let tmpText = ISOStringToDate2(termStartDate) + " / " + termDetail.beginDateDay;
      setTermStartText(termStartText => tmpText);
    }
  }

  const sendRequestToBeginDateAndDayEndpoint = () => {
    if (termDetail) {
      getTermBeginDateAndDay(termDetail.termId);
    }
  }

  //Ders geçmişini getiren servis
  const getLessonHistory = async (termId) => {
    const result = await GetLessonHistory(termId);

    if (result.status === 200) {
      let resultContent = JSON.parse(result.content);

      setLessonHistory(lessonHistory => resultContent.lessonStatusItems);
    }
  }

  const sendRequestToLessonHistoryEndpoint = () => {
    if (termDetail) {
      getLessonHistory(termDetail.termId);
    }
  }

  //Dönem başlangıç tarihi için servis
  const getTermEndDateAndDay = async (termId) => {

    const result = await GetTermEndDateAndDay(termId);

    if (result.status === 200) {
      let resultContent = JSON.parse(result.content);

      let tmpText = ISOStringToDate2(resultContent.date) + " / " + translateWeekDays(resultContent.dayOfDate, language);
      setTermEndText(termEndText => tmpText);
      setTermEndDate(termEndText => new Date(resultContent.beginDate));
    } else {
      setTermEndDate(termEndDate => new Date(termDetail.endDate));
      let tmpText = ISOStringToDate2(termEndDate) + " / " + termDetail.endDateDay;
      setTermEndText(termEndText => tmpText);
    }
  }

  const sendRequestToEndDateAndDayEndpoint = () => {
    if (termDetail) {
      getTermEndDateAndDay(termDetail.termId);
    }
  }

  const getPostponedLessonCount = async (termId) => {
    const result = await GetPostponedLessonCount(termId);

    if(result.status === 200) {
      const resultContent = JSON.parse(result.content);
      setPostponedLessonCount(postponedLessonCount => resultContent);
    }
  }

  const sendRequestToGetPostponedLessonCountEndpoint = () => {
    if(termDetail) {
      getPostponedLessonCount(termDetail?.termId);
    }
  }

  useEffect(() => {
    sendRequestToLessonHistoryEndpoint();
    sendRequestToBeginDateAndDayEndpoint();
    sendRequestToEndDateAndDayEndpoint();
    sendRequestToGetPostponedLessonCountEndpoint();
  }, [])

  return (
    <>
      <div className="w-full flex flex-col md:gap-4 my-2">
        <div className="flex flex-col lg:flex-row lg:justify-between">
          <div className="mb-5 w-full lg:w-[32.5%] relative">
            <div className="flex flex-col px-[10px] pb-0">
              <label
                  className="text-[13px] font-bold mb-2 text-start"
                  htmlFor="term_begin_date"
              >
                {strings.member.member_detail.terms.term_start_date}
              </label>
              <Input
                  id="term_begin_date"
                  type="text"
                  value={termStartText}
                  isReadOnly={true}
              />
            </div>
          </div>
          <div className="mb-5 w-full lg:w-[32.5%]">
            <div className="flex flex-col px-[10px] pb-0">
              <label
                  className="text-[13px] font-bold mb-2 text-start"
                  htmlFor="product_name"
              >
                {strings.member.member_detail.terms.package_name}
              </label>
              <Input
                  id="product_name"
                  type="text"
                  defaultValue={termDetail.productName}
                  isReadOnly={true}
              />
            </div>
          </div>
          <div className="mb-5 w-full lg:w-[32.5%]">
            <div className="flex flex-col px-[10px] pb-0">
              <label
                  className="text-[13px] font-bold mb-2 text-start"
                  htmlFor="term_end_date"
              >
                {strings.member.member_detail.terms.term_end_date}
              </label>
              <Input
                  id="term_end_date"
                  type="text"
                  value={termEndText}
                  isReadOnly={true}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row lg:justify-between">
          <div className="mb-5 w-full lg:w-[32.5%] relative">
            <div className="flex flex-col px-[10px] pb-0">
              <label
                  className="text-[13px] font-bold mb-2 text-start"
                  htmlFor="student_name"
              >
                {strings.member.member_detail.terms.student_name}
              </label>
              <Input
                  id="student_name"
                  type="text"
                  value={termDetail?.studentName}
                  isReadOnly={true}
              />
            </div>
          </div>
          <div className="mb-5 w-full lg:w-[32.5%]">
            <div className="flex flex-col px-[10px] pb-0">
              <label
                  className="text-[13px] font-bold mb-2 text-start"
                  htmlFor="term_status"
              >
                {strings.member.member_detail.terms.term_status}
              </label>
              <Input
                  id="term_status"
                  type="text"
                  defaultValue={termStatus[language]}
                  isReadOnly={true}
              />
            </div>
          </div>
          <div className="mb-5 w-full lg:w-[32.5%]">
            <div className="flex flex-col px-[10px] pb-0 relative">
              <label className="text-[13px] font-bold mb-2 text-start" htmlFor="total_lesson">
                {strings.member.member_detail.terms.total_lesson}
              </label>
              <Input
                  id="total_lesson"
                  type="text"
                  value={termDetail?.lessonCountModel?.totalLesson}
                  isReadOnly={true}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row lg:justify-between">
          <div className="mb-5 w-full lg:w-[32.5%]">
            <div className="flex flex-col px-[10px] pb-0">
              <label
                  className="text-[13px] font-bold mb-2 text-start"
                  htmlFor="attended_lesson"
              >
                {strings.member.member_detail.terms.attended_lesson_count}
              </label>
              <Input
                  id="attended_lesson"
                  type="text"
                  value={termDetail?.lessonCountModel?.attendedLesson}
                  isReadOnly={true}
              />
            </div>
          </div>
          <div className="mb-5 w-full lg:w-[32.5%]">
            <div className="flex flex-col px-[10px] pb-0">
              <label
                  className="text-[13px] font-bold mb-2 text-start"
                  htmlFor="remaining_lesson"
              >
                {strings.member.member_detail.terms.remaining_lesson_count}
              </label>
              <Input
                  id="remaining_lesson"
                  type="text"
                  value={termDetail?.lessonCountModel?.remainingLesson}
                  isReadOnly={true}
              />
            </div>
          </div>
          <div className="mb-5 w-full lg:w-[32.5%]">
            <div className="flex flex-col px-[10px] pb-0">
              <label
                  className="text-[13px] font-bold mb-2 text-start"
                  htmlFor="freeze_right"
              >
                {strings.member.member_detail.terms.freeze_lesson_count}
              </label>
              <Input
                  id="freeze_right"
                  type="text"
                  defaultValue={termDetail?.termRightModel?.freezeRight}
                  isReadOnly={true}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row lg:justify-between">
          <div className="mb-5 w-full lg:w-[32.5%]">
            <div className="flex flex-col px-[10px] pb-0">
              <label
                  className="text-[13px] font-bold mb-2 text-start"
                  htmlFor="postpone_right">
                {strings.member.member_detail.terms.postpone_lesson_right}
              </label>
              <Input
                  id="postpone_right"
                  type="text"
                  value={postponedLessonCount}
                  isReadOnly={true}
              />
            </div>
          </div>
          <div className="mb-5 w-full lg:w-[32.5%]">
            <div className="flex flex-col px-[10px] pb-0">
              <label
                  className="text-[13px] font-bold mb-2 text-start"
                  htmlFor="term_level"
              >
                {strings.member.member_detail.terms.level}
              </label>
              <Input
                  id="term_level"
                  type="text"
                  defaultValue={termDetail.termLevel ? termDetail.termLevel : notSpecified}
                  isReadOnly={true}
              />
            </div>
          </div>
          <div className="mb-5 w-full lg:w-[32.5%]">
            <div className="flex flex-col px-[10px] pb-0">
              <label
                  className="text-[13px] font-bold mb-2 text-start"
                  htmlFor="last_subject"
              >
                {strings.member.member_detail.terms.last_subject}
              </label>
              <Input
                  id="last_subject"
                  type="text"
                  defaultValue={termDetail.lastSubjectOfWeek ? termDetail.lastSubjectOfWeek : notSpecified}
                  isReadOnly={true}
              />
            </div>
          </div>
        </div>
        {lessonHistory.length > 0 && (
          <div>
            <p className="text-[13px] font-bold mb-2 text-start ml-2">
              {strings.member.member_detail.terms.lesson_history}
            </p>
            <div className="flex flex-wrap mt-5 gap-x-4 gap-y-2 mx-2">
              {lessonHistory.length > 0 && lessonHistory.map((lesson, index) => {
                const icon = iconForLessonStatus(lesson.status, language);
                return (
                  <LessonTime
                    key={index}
                    tooltip={icon.tooltip}
                    iconName={icon.iconName}
                    iconClassnames={icon.color}
                    date={lesson.date}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TermContent;
