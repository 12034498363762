import {useLocalization} from "../../../../hooks/useLocalization";
import {convertStringToPercentageFormat} from "../../../../components/Utility";

const StudentTableRowStatic = ({ total }) => {

  const strings = useLocalization();

  return (
      <tr className="bg-[#f0f0f0]">
          <td className="td align-middle py-4">
              <div
                  className="font-bold text-blue focus:underline min-w-max"
              >
                  <p className="min-w-max">{strings.table.total_upper}</p>
              </div>
          </td>
          <td className="td align-middle font-bold">
              {total.totalAttendedLessonCount}
          </td>
          <td className="td align-middle font-bold">
              {convertStringToPercentageFormat(total.averageAttendanceRate)}
          </td>
          <td className="td align-middle font-bold">
              {total.totalRemainingLessonCount}
          </td>
          <td className="td align-middle font-bold">
              -
          </td>
          <td className="td align-middle font-bold">
              -
          </td>
      </tr>
  )
}

export default StudentTableRowStatic;