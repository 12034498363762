import {useLocalization} from "../../../../hooks/useLocalization";
import {Loading, Modal, PageTitle} from "../../../../components";
import {useEffect, useState} from "react";
import LetterNoteInformationModal from "./LetterNoteInformationModal";
import {GetAverageLessonSkills} from "../../../../services/Member/MemberStudentService";

const LessonDetail = ({ memberId, termId }) => {

    const strings = useLocalization();

    const [modalMessage, setModalMessage] = useState("");
    const [detailModal, setDetailModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});

    const getLessonSkillsAverage = async (termId) => {

        const result = await GetAverageLessonSkills(termId);

        if(result.status === 200) {
            let resultContent = JSON.parse(result.content);

            letterNoteTitles?.map((title) => {
                resultContent[title] =
                    resultContent[title] = parseInt(resultContent[title]) > 7
                        ? parseInt(resultContent[title])
                        : parseInt(resultContent[title]) + 1;
            })

            setData(data => resultContent);
        }

        setLoading(loading => false);
    }

    //Term Schedule modalda ders notları başlıklarını index'e göre yazdıran fonksiyon
    const getStatisticsTitle = (title) => {
        if (title === "speakingGrammarUsage") {
            return strings.member.student.lesson_detail.speaking_grammar_usage;
        } else if (title === "speakingVocabularyUsage") {
            return strings.member.student.lesson_detail.speaking_vocabulary_usage;
        } else if (title === "speakingPronunciation") {
            return strings.member.student.lesson_detail.speaking_pronunciation;
        } else if (title === "listeningComprehension") {
            return strings.member.student.lesson_detail.listening_comprehension;
        } else if (title === "readingVocabularyAwareness") {
            return strings.member.student.lesson_detail.reading_vocabulary_awareness;
        } else if (title === "readingPronunciation") {
            return strings.member.student.lesson_detail.reading_pronunciation;
        }
    };

    let letterNoteTitles = ["speakingGrammarUsage", "speakingVocabularyUsage", "speakingPronunciation",
        "listeningComprehension", "readingVocabularyAwareness", "readingPronunciation"];

    const handleLetterInfoClick = (letterNote, index) => {
        letterNote = parseInt(letterNote) > 7 ? parseInt(letterNote) : parseInt(letterNote) + 1
        let message = getMessage(letterNote, index);
        setModalMessage(message)
        setDetailModal(true);
    };

    //lessonNote'e göre not açıklamasını getiren fonksiyon
    const getMessage = (letterNote, index) => {
        const notes = ["one", "two_three", "four_five", "six_seven", "eight_nine", "ten"];
        const lessonInfo = strings.member.student.lesson_detail.letter_note_informations;
        const lessonKeys = Object.keys(lessonInfo);

        if (index < 0 || index >= lessonKeys.length) return;

        const lessonKey = lessonKeys[index];
        const noteIndex = letterNote === 1 ? 0
            : letterNote === 2 || letterNote === 3 ? 1
                : letterNote === 4 || letterNote === 5 ? 2
                    : letterNote === 6 || letterNote === 7 ? 3
                        : letterNote === 8 || letterNote === 9 ? 4
                            : 5;

        return lessonInfo[lessonKey][notes[noteIndex]];
    }

    useEffect(() => {
        if(termId.length > 0){
            getLessonSkillsAverage(termId);
        }
    }, []);

    return (
        <>
            <PageTitle title={strings.member.student.lesson_detail.title} />
            <div className="bg-white border border-[#e7e7e7] px-2 xs:px-[15px] py-[10px] rounded-md text-center">
                {loading && (
                    <div className="mb-5">
                        <Loading />
                    </div>
                )}

                {!loading && (
                    <div className="max-w-max flex flex-wrap gap-5 mt-5 mx-auto justify-center mb-5">
                {letterNoteTitles.map((title, index) => (
                    <div key={index} className="font-bold max-w-[70px] flex flex-col justify-end text-center">
                        <p className="mb-2">{getStatisticsTitle(title)}</p>
                        <div
                            className="letter-note cursor-pointer relative group"
                        >
                            {(data[title] === "" || data[title] === 1) ? (
                                <span>-</span>
                            ) : (
                                <div
                                    className="align-bottom"
                                    onClick={() => handleLetterInfoClick(data[title], index)}
                                >
                                    <span className="text-blue group-hover:text-secondary text-[18px]">
                                        {data[title]}
                                    </span>
                                    <span className="text-blue group-hover:text-secondary">/10</span>
                                    <div className="tooltip-top -top-[38px] font-normal">
                                        <p>{strings.member.student.lesson_detail.click_for_lesson_detail}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
                )}
            </div>

            {/* --- Modal --- */}
            <Modal modal={detailModal} setModal={setDetailModal} classes={{ modal: "h-[400px] max-w-[850px]", overlay: "-mt-[44px]" }} >
                <LetterNoteInformationModal modalMessage={modalMessage} />
            </Modal>
        </>
    )

}

export default LessonDetail;