import {NoRecordsFound} from "../../../../components";
import {useLocalization} from "../../../../hooks/useLocalization";
import {StudentTableRow} from "../index";
import StudentTableRowStatic from "./StudentTableRowStatic";

const StudentTable = ({ data, total, errorMessage }) => {

    const strings = useLocalization();

    return(
        <div className="mx-auto px-4">
            <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
                <div className="inline-block min-w-full rounded-lg overflow-hidden">
                    <table className="min-w-full leading-normal text-header">
                        <thead>
                        <tr>
                            <th className="th"><p className="min-w-max">{strings.member.student.name_surname}</p></th>
                            <th className="th"><p className="min-w-max">{strings.member.student.attended_lesson_count}</p></th>
                            <th className="th"><p className="min-w-max">{strings.member.student.attended_rate}</p></th>
                            <th className="th"><p className="min-w-max">{strings.member.student.remaining_lesson_count}</p></th>
                            <th className="th"><p className="min-w-max">{strings.member.student.start_level}</p></th>
                            <th className="th"><p className="min-w-max">{strings.member.student.current_level}</p></th>
                        </tr>
                        </thead>
                        <tbody>
                            {data?.length > 0 && (
                                <>
                                    {data.map((member,index) => (
                                        <StudentTableRow
                                            key={index}
                                            member={member}
                                        />
                                    ))}
                                    <StudentTableRowStatic total={total} />
                                </>
                            )}

                            {data?.length === 0 && (
                                <tr className="bg-white hover:bg-[#f0f0f0]">
                                    <td colSpan="6">
                                        <NoRecordsFound/>
                                    </td>
                                </tr>
                            )}

                            {errorMessage.length > 0 && (
                                <tr className="bg-white hover:bg-[#f0f0f0]">
                                    <td colSpan="6">
                                        <div className="py-4 min-w-full text-center">{errorMessage}</div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    );
}

export default StudentTable;