import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom'
import {getCookie} from 'ko-basic-cookie';
import {cookieName} from '../../consts/Constants';
import {url} from '../../routes/utility';

const CheckAuth = () => {

    const navigate = useNavigate();
    const token = getCookie(cookieName) || false;

    useEffect(() => {

        if (token) {
            navigate(url("student"));
        }
        else {
            navigate(url("auth.login"));
        }

    }, [token])
}

export default CheckAuth