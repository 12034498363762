import React, { forwardRef } from "react";
import { useDispatch } from "react-redux";
import useAnalytics from "../../hooks/useAnalytics";
import useAuth from "../../hooks/useAuth";
import { useLocalization } from "../../hooks/useLocalization";
import { employeeLogout } from "../../store/EmployeeSlice";
import {DefaultProfile} from "../../assets/img";

const NavbarProfile = forwardRef(() => {
    
  const dispatch = useDispatch();
  const strings = useLocalization();
  const { employee } = useAuth();
  const analytics = useAnalytics();

  return (
    <>
      <div className="flex px-4">
        <img
          className="rounded-lg"
          src={DefaultProfile}
          width="60"
          height="60"
          alt=""
        />
        <p className="my-auto ml-2 font-bold text-[#777]">{employee.companyName}</p>
      </div>

      <hr className="mb-3 mt-4 px-4" />
      <button
        onClick={() => {
          dispatch(employeeLogout());
          analytics.useAnalytics('Kullanici_Cikis_Yapti');
        }}
        className="block px-5 py-[3px] cursor-pointer hover:bg-[#F8F9FA] focus:bg-[#F8F9FA] w-full text-start"
        tabIndex="-1"
      >
        {strings.sidebar.profile.logout}
      </button>
    </>
  );
});

export default NavbarProfile;
