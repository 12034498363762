import {useEffect, useRef} from "react";
import OutsideClickHandler from "react-outside-click-handler";
import {useDispatch} from "react-redux";
import {Link, useLocation} from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import {useLocalization} from "../../hooks/useLocalization";
import {url} from "../../routes/utility";
import {employeeLogout} from "../../store/EmployeeSlice";
import {animateArrow, closeSidebarMobile, toggleProfile, toggleSidebarMobile} from "../Toggle";
import {MemberOperations} from "./components";
import {DefaultProfile} from "../../assets/img";

const SidebarMobile = () => {

  const strings = useLocalization();
  const dispatch = useDispatch();
  const { employee, employeeType } = useAuth();

  const sidebarRef = useRef();
  const profileContentRef = useRef();
  const mobileOverlayRef = useRef();
  const mobileButtonRef = useRef();
  const animateArrowRef = useRef();
  const windowSize = useRef(window.innerWidth);

  const location = useLocation();
  const pathName = location.pathname || "/dashboard";

  useEffect(() => {
    const handleSidebarOpen = () => {
      if (windowSize.current && windowSize.current < 768) {
        toggleSidebarMobile(sidebarRef, mobileOverlayRef, mobileButtonRef);
      }
    };

    handleSidebarOpen();
  }, [windowSize.current, pathName]);

  return (
    <>
      <div className="relative z-40 md:hidden" role="dialog" aria-modal="true">
        <div
          ref={mobileOverlayRef}
          id="transparent_bg"
          className="fixed inset-0 bg-gray-600 bg-opacity-75 animate-fadeIn"
        ></div>
        <OutsideClickHandler
          onOutsideClick={() =>
            closeSidebarMobile(sidebarRef, mobileOverlayRef, mobileButtonRef)
          }
        >
          <div
            id="sidebar_mobile"
            ref={sidebarRef}
            className="fixed w-[250px] inset-0 z-40 flex duration-500"
          >
            <div className="relative flex w-full max-w-[250px] flex-1 flex-col bg-white pb-4">
              <div className="flex items-center justify-between pr-2 px-3 bg-blue h-[62px] shadow-lg">
                <Link
                    to={url("student")}
                    className="flex flex-shrink-0 items-center px-4 mb-[3px]"
                >
                  <p className="text-white font-bold text-[15px] max-w-[141px] leading-5">Konuşarak Öğren Kurumsal Panel</p>
                </Link>

                {/* <!-- Mobil view --> */}
                <button
                    ref={mobileButtonRef}
                  className="mr-4"
                  onClick={() =>
                    toggleSidebarMobile(
                      sidebarRef,
                      mobileOverlayRef,
                      mobileButtonRef
                    )
                  }
                >
                  <span className="material-symbols-outlined text-white text-3xl menu-btn">
                    menu
                  </span>
                </button>
              </div>
              <div className="h-0 flex-1 overflow-y-scroll sm:overflow-y-hidden hover:overflow-y-scroll">
                {/* <!-- Mobil --> */}
                <nav className="space-y-1 px-2">
                  <div>
                    <div id="accordion_mobil" className="w-full text-[13px]">
                      <input
                        type="checkbox"
                        name="panel"
                        id="panel-mobil"
                        className="hidden"
                      />
                      <a
                        id="panel-mobil"
                        htmlFor="panel-1"
                        className="label relative block bg-transparent px-4 py-4 cursor-pointer"
                        onClick={() => {
                          toggleProfile(profileContentRef);
                          animateArrow(animateArrowRef);
                        }}
                      >
                        <div className="flex items-center px-1">
                          <div className="ml-2 flex items-center justify-between w-full toggled">
                            <p className="text-[#777] font-bold text-[14px]">
                              {employee.companyName}
                            </p>
                            <div
                                ref={animateArrowRef}
                                className="dropdown-arrow transition-transform duration-500 absolute right-4 top-[calc(50%-2px)]"
                            ></div>
                          </div>
                          <img
                            className="rounded-full order-first"
                            src={DefaultProfile}
                            width="42"
                            height="42"
                            alt=""
                          />
                        </div>
                      </a>
                      <div
                        ref={profileContentRef}
                        className="accordion__content py-3 px-5 overflow-hidden transition-max-height duration-500 animate-fadeOut max-h-0 bg-transparent -mt-[25px] w-full"
                      >
                        <p className="mb-1">
                          {strings.sidebar.profile.my_profile}
                        </p>
                        <a
                          className="py-1 px-2 cursor-pointer hover:bg-gray-100 focus:bg-blue flex w-full"
                          onClick={() => dispatch(employeeLogout())}
                        >
                          {strings.sidebar.profile.logout}
                        </a>
                      </div>
                    </div>
                    <hr className="mx-2" />
                  </div>
                  <div className="pt-4">
                    <MemberOperations />
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    </>
  );
};

export default SidebarMobile;
