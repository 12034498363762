import {Link, useLocation, useParams} from "react-router-dom";
import {LessonDetail, StudentTermDetail} from "./components";
import {url} from "../../routes/utility";

const StudentDetail = () => {

    const { memberId } = useParams();
    const location = useLocation();
    const termId = location?.state?.termId;

    return (
        <>
            <Link
                to={url("student")}
                className="w-[50px] h-[31px] bg-card flex items-center justify-center rounded-full"
            >
                <span className="material-symbols-outlined font-bold">arrow_back</span>
            </Link>
            <StudentTermDetail memberId={memberId} termId={termId} />
            <LessonDetail memberId={memberId} termId={termId} />
        </>
    )
}

export default StudentDetail;
